import React, { useState } from "react";

export default function Search({ items }) {
  const [searchTerm, setSearchTerm] = useState("");

  const handleChange = (e) => {
    console.log(setSearchTerm(e.target.value));
  };

  if (!items) {
    return <>Loading...</>;
  }

  return (
    <>
      <input className="search-input" id="search" onChange={handleChange} autoComplete="off" placeholder="Type to filter..."/>
      <br />
      <ul>
        {items
          .filter((item) => {
            return (
              searchTerm == "" ||
              item.name.toLowerCase().includes(searchTerm.toLowerCase())
            );
          })
          .map((item) => (
            <li className="location-link" key={item.name}><a href={item.url}>{item.name}</a></li>
          ))}
      </ul>
    </>
  );
}
